<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      :headers="headers"
      do-route
      do-route-filters
      multiple-select
      :show-operations="false"
      :resource-limit="1000"
      resource="discounts"
      :resource-query="{ query: resourceQuery, branch: currentLocation }"
      no-meta-check
      route-base="/products/discounts"
      show-search-filter
      :search-filters="filtersList"
      :buttons="computedButtons"
      :search-filter-config="{ allowTextInput: true }"
      @selection-change="handleSelectionChange"
      @loading-error="handleLoadingError"
    >
      <template #actions>
        <div>
          <actions
            v-permissions="{
              scopes: ['products:discounts:delete']
            }"
            :selected-items="selectedItems"
            @delete-requested="handleDelete"
          />
        </div>
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import qs from 'qs'
import pick from 'just-pick'
import safeGet from 'just-safe-get'
import Decimal from 'decimal.js-light'
import th from '@tillhub/javascript-sdk'
import Actions from '@components/actions/delete'
import isEmpty from 'just-is-empty'
import { useMessagesStore } from '@/store/messages'

export default {
  metaInfo() {
    return {
      title: this.$t('pages.discounts.title')
    }
  },
  components: {
    Actions
  },
  data() {
    return {
      loadingExport: false,
      selectedItems: [],
      searchFilters: {},
      filtersList: [
        {
          name: 'type',
          type: 'select',
          label: this.$t('pages.discounts.edit.form.properties.group.label'),
          options: [
            {
              label: this.$t(
                'pages.discounts.edit.form.properties.group.values.item'
              ),
              value: 'item'
            },
            {
              label: this.$t(
                'pages.discounts.edit.form.properties.group.values.cart'
              ),
              value: 'cart'
            }
          ]
        }
      ],
      width: 500,
      headers: [
        {
          field: 'name',
          label: this.$t('common.headers.name.title'),
          fallback: '-',
          minWidth: 160,
          truncate: true
        },
        {
          field: 'discount',
          label: this.$t('pages.discounts.all.headers.discount'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          align: 'right',
          formatter: (row, column) => {
            if (row.type === 'percentage' && Number.isFinite(row.rate))
              return `${new Decimal(row.rate).times(100).toString()} %`
            if (row.type === 'value' && Number.isFinite(row.value))
              return this.$formatCurrency(row.value, row.currency || 'EUR')
            return '-'
          }
        },
        {
          field: 'application_style',
          label: this.$t('pages.discounts.all.headers.application'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: (row) => {
            const behavior = safeGet(row, 'behaviors.cart')
            return behavior
              ? this.translations[behavior]
              : this.translations[row.group]
          }
        },
        {
          field: 'constraints',
          label: this.$t('pages.discounts.all.headers.constraints'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: (row) => {
            const filteredConstraints = Object.entries(row.constraints || {})
              .filter(([cKey, cValue]) => {
                if (cKey === 'time') {
                  // special check for time constraint, that it includes at least one enabled constraint
                  return this.checkPropDeep(
                    row.constraints.time,
                    'enabled',
                    true
                  )
                }
                return !isEmpty(cValue)
              })
              .map(([c]) => this.translations[c])

            return filteredConstraints.length
              ? filteredConstraints.join(', ')
              : '–'
          }
        },
        {
          field: 'type',
          label: this.$t('common.headers.type.title'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: (row) => this.translations[row.type]
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation'
    }),
    translations() {
      return {
        per_cart: this.$t('common.discounts.behaviors.cart.per_cart'),
        per_item: this.$t('common.discounts.behaviors.cart.per_item'),
        cart: this.$t('common.discounts.groups.cart'),
        customer: this.$t('common.discounts.groups.customer'),
        item: this.$t('common.discounts.groups.item'),
        products: this.$t('common.discounts.constraints.products'),
        product_groups: this.$t('common.discounts.constraints.product_groups'),
        taxes: this.$t('common.discounts.constraints.taxes'),
        time: this.$t('common.discounts.constraints.time'),
        percentage: this.$t('common.discounts.types.percentage'),
        value: this.$t('common.discounts.types.value')
      }
    },
    parsedFilter() {
      if (this.$route.query && this.$route.query) {
        const filter = qs.parse(this.$route.query) || {}

        this.$log.debug(filter)
        return filter.filter
      }

      return null
    },
    resourceQuery() {
      return {
        deleted: false
      }
    },
    hasFilters() {
      return !!this.parsedFilter
    },
    buttons() {
      return [
        {
          type: 'create',
          scopes: ['products:discounts:create']
        },
        {
          scopes: ['products:discounts:create'],
          icon: 'th-icon-plus',
          label: `${this.$t('common.forms.labels.new')} v1`,
          clickHandler: () => this.$router.push({ name: 'discounts-new-v1' })
        }
      ]
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  mounted() {
    this.$emitter.on('refresh-requested', () => {
      this.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    injectFilter() {
      if (!this.parsedFilter) return {}

      const filters = {}
      Object.keys(this.parsedFilter).forEach((key) => {
        const map = this.filtersList.find((item) => item.name === key)
        if (!map) return

        filters[key] = {
          label: `${map.label}: ${this.parsedFilter[key]}`,
          value: this.parsedFilter[key],
          name: key
        }
      })

      return filters
    },
    submitFilter(filters) {
      const searchFilters = Object.keys(filters).reduce((acc, curr) => {
        return { ...acc, [curr]: filters[curr].value }
      }, {})
      this.searchFilters = searchFilters || {}

      this.handleSearchSubmit()
    },
    handleSearchSubmit() {
      const q = pick(this.searchFilters, [
        'q',
        'firstname',
        'lastname',
        'email',
        'phonenumbers',
        'postal_code',
        'address',
        'company'
      ])

      const filter = qs.stringify({
        filter: q
      })
      this.$router.push(`/discounts?${filter}`)
    },
    resetFilter() {
      this.searchFilters = {}
    },
    handleSelectionChange(val) {
      this.selectedItems = val
    },
    refresh() {
      this.$refs.table.refresh()
    },
    async handleDelete(payload) {
      const confirm = await this.$askToDeleteMany(
        this.selectedItems,
        this.$t('common.resource.discount.plural').toLowerCase()
      )
      if (confirm) this.deleteDiscount(payload)
    },
    async deleteDiscount(payload) {
      if (this.selectedItems.length > 1) {
        this.handleBulkDelete(this.selectedItems)
        return
      }
      const successMessage = this.$t('common.success.action.delete.single', {
        resource: this.$t('common.resource.discount.singular')
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.discount.singular')
      })

      try {
        const inst = th.discounts()
        await inst.delete(payload[0].id)
        this.$message({
          type: 'success',
          message: successMessage
        })
        this.refresh()
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    handleBulkDelete(items) {
      const inst = th.discounts()

      const operations = items.map((item) => {
        return () => inst.delete(item.id)
      })

      const label = this.$t('common.error.action.delete.multiple', {
        resources: this.$t('common.resource.discount.plural')
      })

      const currentRoute = this.$route.fullPath

      const fulfillment = () => {
        if (this.$route.fullPath === currentRoute) {
          this.refresh()
        }
      }

      useMessagesStore().startLocalOperation({
        operations,
        label,
        fulfillment
      })
    },
    filterLogger(message, ...args) {
      // console.log(message, ...args) // eslint-disable-line
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.discounts.title')
        })
      })
    },
    /**
     * Takes an object together with a key/value pair,
     * and does a deep-search to check if this key/value pair exists anywhere in the object
     * @param {object} base - base object
     * @param {string} k - property name
     * @param {*} v - property value
     * @returns {boolean} true if k/v pair exists anywhere in base object, false otherwise
     */
    checkPropDeep(base, k, v) {
      if (!base || typeof base !== 'object') return false

      // using for-of to be able to return on first match
      for (const [eK, eV] of Object.entries(base)) {
        // if first condition does not match, recursively call function on values
        if ((eK === k && eV === v) || this.checkPropDeep(eV, k, v)) {
          return true
        }
      }

      return false
    }
  }
}
</script>

<style scoped>
.actions {
  display: flex;
  justify-content: flex-start;
  justify-items: flex-start;
  align-content: center;
  align-items: center;
}

.actions > * {
  margin-right: 10px;
}
</style>
